import React, { useState, useEffect, useMemo } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { positiveNumber, cleanChannelIdentifier } from '../../common/v5/helpers.js';
import {I} from '../../common/v5/config.js';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import { ColoredFlag } from '../../components/v5/BriefErrand';
import moment from 'moment';
import {
	PaginationSize
} from '../../reactcomponents/SimpleTable';
import {parseDate, getErrandIcons, createMarkup, isDesktopMode, stripHTML} from '../../common/v5/helpers.js';
import {
	FILE_EXCEED_MESSAGE,
	ERRAND_PAGINATION_SIZE_OPT,
	PREF_LIST_VIEW,
	PREF_CONVERSATION_VIEW
} from '../../common/v5/constants';
import Measure from 'react-measure';
import { isMobile } from '../../redux/util';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const POPPER_MODIFIRES = {
	offset: { offset: '50px, 0px' }
	, preventOverflow: {
		enabled: true,
		boundariesElement: 'viewport',
		padding: 20
	}
	, flip: { enabled: true }
	, hide: { enabled: false }
}

export function ErrandPreviewItem(props) {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [previewDimensions, setPreviewDimensions] = useState("");
	const [domReady, setDomReady] = useState(false);

	const {
		id,
		activate,
		body,
		channel,
		date,
		delayTime,
		from,
		instantPreview,
		hideMeta,
		placement,
		subject,
		targetElement,
		initiator
	} = props;

	useEffect(() => {
		setDomReady(true);
		return () => {
			setTooltipOpen(false);
		};
	}, []);

	const mod = useMemo(() => {
		if (initiator === PREF_CONVERSATION_VIEW) {
			return POPPER_MODIFIRES;
		}
		return {};
	}, [initiator]);

	const toggle = () => {
		if (!tooltipOpen) {
			props.onActiveSubject(true, id);
			props.onLoad(props.id);
			setTooltipOpen(true);
		} else {
			props.onActiveSubject(false, id);
			setTooltipOpen(false);
		}
	};

	const onDisableTooltipClick = (e) => {
		e.stopPropagation();
	};

	const createMarkup = (html) => {
		return { __html: html };
	};


	let delay = 0;
	if (!instantPreview && delayTime != null) {
		delay = delayTime * 1000;
	}

	let resizePreview = true;
	let previewInnerHeight = 100;
	if (previewDimensions && previewDimensions.height > 0) {
		previewInnerHeight = `${previewDimensions.height + 100}px`;
	}

	return (
		domReady && (
			<Tooltip
				autohide={false}
				className={classNames('errand-preview', { large: resizePreview })}
				container={'body'}
				delay={{ show: delay, hide: 0 }}
				hideArrow={true}
				isOpen={activate && tooltipOpen}
				modifiers={mod}
				placement={placement}
				target={targetElement}
				style={{ height: previewInnerHeight }}
				toggle={toggle}
			>
				<div
					className="errand-preview-subject"
					dangerouslySetInnerHTML={createMarkup(subject)}
					onClick={onDisableTooltipClick}
				/>
				<div className={classNames('errand-preview-body', { 'preview--16x9': resizePreview })} onClick={onDisableTooltipClick}>
					<Measure
						bounds
						onResize={(contentRect) => {
							setPreviewDimensions(contentRect.bounds);
						}}
					>
						{({ measureRef }) => (
							<div ref={measureRef} className={classNames('prevInnerBody', { 'preview__inner-wrapper': resizePreview })} dangerouslySetInnerHTML={createMarkup(body)} />
						)}
					</Measure>
				</div>
				<div className="errand-preview-meta" hidden={hideMeta} onClick={onDisableTooltipClick}>
					<span className="preview-meta-left">{from} </span>
					<span className="preview-meta-right">
						{I('Sent')} {date} {I('from')} <span className="preview-meta-right-blue">{channel}</span>
					</span>
				</div>
			</Tooltip>
		)
	);
}

const WfNoticeIcon = ({className, hidden, iconName, title }) => {

	return (
		<div className="wf-notices">
			<ul className="wf-list-view-status-icons">
				<li className={className} hidden={hidden} title={title}>
					<i className={iconName}></i>
				</li>
			</ul>
		</div>
	)
}

class WorkflowTable extends React.Component {
	//TODO: reponsive ui
	
	constructor(props) {
		super(props);
		this.onSortChange = this.onSortChange.bind(this);
		this.onRowSelect = this.onRowSelect.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
		this.htmlFormatter = this.htmlFormatter.bind(this);
		this.channelFormatter = this.channelFormatter.bind(this);
		this.dateFormatter = this.dateFormatter.bind(this);
		this.priorityFormatter = this.priorityFormatter.bind(this);
		this.subjectFormatter = this.subjectFormatter.bind(this);
		this.fromFormatter = this.fromFormatter.bind(this);
		this.idFormatter = this.idFormatter.bind(this);
		this.areaFormatter = this.areaFormatter.bind(this);
		this.formatRowStyle = this.formatRowStyle.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.sizePerPageListChange = this.sizePerPageListChange.bind(this);
		this.renderPaginationPanel = this.renderPaginationPanel.bind(this);
		this.customSelector = this.customSelector.bind(this);
		this.loadPreview = this.loadPreview.bind(this);
		this.handleGetCollabInfo = this.handleGetCollabInfo.bind(this);
		this.handleResetCollabInfo = this.handleResetCollabInfo.bind(this);
		this.handleCollabClick = this.handleCollabClick.bind(this);
		this.renderStatusIcons = this.renderStatusIcons.bind(this);
		this.handleGetSlaTime = this.handleGetSlaTime.bind(this);
		this.handleResetSlaTime = this.handleResetSlaTime.bind(this);
		this.state = {
			collabShowStatus: 0,
			slaShowTime: 0,
			columnWidths: [
				{colName: "checkbox", width: 30},
				{colName: "priority", width: 65},
				{colName: "contact", width: 120},
				{colName: "channels", width: 60},
				{colName: "errands", width: 90},
				{colName: "subject", width: 210},
				{colName: "sort", width: 30},
				{colName: "organization", width: 120},
				{colName: "area", width: 110},
				{colName: "addressTo", width: 120},
				{colName: "date", width: 140},
				{colName: "flag", width: 70},
			]
		}
	}
	
	componentDidMount() {
		for (let index = 0; index < 13; index += 1) {
			if ($(`.react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns
			$(`.react-bs-container-header thead th:eq(${index})`).resizable({
			  minWidth: 30,
			//   maxWidth: 200,
			  handles: 'e',
			  resize: (__event, ui) => {
				const newColumnWidths = [...this.state.columnWidths];
				newColumnWidths[index].width = ui.size.width;
				this.setState({
					columnWidths: newColumnWidths
				});
			  },
			});
		}
	}
	
	onSortChange(sortName, sortOrder) {
		//TODO: Sort by 'attachment' seems not exist on the query.
		if(this.props.onSortList){
			this.props.onSortList(sortName, sortOrder);
		}
	}
	onRowSelect(row, isSelected, e){
		if(typeof row !== 'undefined' && typeof row.id !== 'undefined') {
			if(this.props.onSelectRow) {
				if(this.props.activeCreatedErrand !== row.displayId || this.props.sipMakeCallCurrentErrand) {
					this.props.onSelectRow(row.id, isSelected);
				}
			}
		}
	}
	onRowClick({ chat, cipherKey, handleId, id, displayId }, columnIndex) {
		const { activeCreatedErrand, sipMakeCallCurrentErrand, onClickRow } = this.props;
		if (typeof onClickRow !== "function") {
			return;
		}
		if(activeCreatedErrand !== displayId || sipMakeCallCurrentErrand ) {
			onClickRow(id, chat, cipherKey);
		}
	}
	idFormatter(cell, row) {
		if(this.props.activeCreatedErrand === row.displayId || this.props.sipGetCurrentEid === row.id) {
			return <span className="highlight-active-call">{"#"+row.displayId}</span>
		} else {
			return "#"+row.displayId;
		}
	}
	htmlFormatter(cell,row){
		var formattedCell = createMarkup(cell);
		return <div dangerouslySetInnerHTML={formattedCell} />;
	}
	channelFormatter(cell, row){
		let formattedCell = "", unread = 0, hideNewMessages = true;

		if(row.chat){
			if(row.chat.unReadMsgs !== 0){
				unread = row.chat.unReadMsgs;
				hideNewMessages = false;
			}
		}

		formattedCell = getErrandIcons(row.channelId, row.chat, row.isPm, row.sourceId);
		return <div className="conversation-list-type">
					{formattedCell}
					<span hidden={hideNewMessages} className="message-count">{unread}</span>
			</div>;
	}
	dateFormatter(cell, row){
		let date = "<span>"+cell+"</span> <i class='icon-chevron-right'></i>";
		var formattedCell = createMarkup(date);
		return <div dangerouslySetInnerHTML={formattedCell} />;
	}
	fromFormatter(cell, row){
		let serviceSource = row.channel;
		if(row.channel == "Chat" && row.sourceName){
			serviceSource = row.sourceName
		}
		let cFrom = cleanChannelIdentifier(serviceSource, row.from);
		var formattedCell = createMarkup(cFrom), errorMsg, errorTitle, fromName;
		if( cFrom ){
			if(cFrom.length > 30) {
				fromName = cFrom.substring(0,30)+'...';
			}else{
				fromName = cFrom;
			}
			formattedCell = createMarkup(fromName);
			if(row.statusMessage === FILE_EXCEED_MESSAGE){
				errorMsg = "<i class='error fa fa-exclamation'></i>"+fromName;
				formattedCell = createMarkup(errorMsg);
				errorTitle = row.statusMessage;
			}
		}
		//FIXME: Handle for some other errors too.
		return <div title={errorTitle} dangerouslySetInnerHTML={formattedCell} />;
	}
	toFormatter(cell, row){
		var formattedTo = cell
		if(cell && cell.length > 30) {
			formattedTo = cell.substring(0,30)+'...';
		}
		return formattedTo;
	}
	areaFormatter(cell, row) {
		if(this.props.activeCreatedErrand === row.displayId || this.props.sipGetCurrentEid === row.id) {
			return <span className="has-active-call">{row.areaName}</span>
		} else {
			return row.areaName;
		}
	}
	handleGetCollabInfo(id) {
		if(this.props.onGetCollabInfo) {
			this.props.onGetCollabInfo(id);
			this.setState({ collabShowStatus: id });
		}
	}
	handleResetCollabInfo() {
		this.setState({ collabShowStatus: 0 });
	}
	handleCollabClick(e, id) {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.canOffCollaborationLight) {
			if (this.props.onToggleLight) {
				this.props.onToggleLight(id);
			}
		}
	}
	handleGetSlaTime(id) {
		if(this.props.onGetSlaTime && this.props.showSlaTimeFeature) {
			this.props.onGetSlaTime(id);
			this.setState({ slaShowTime: id });
		}
	}
	handleResetSlaTime() {
		this.setState({ slaShowTime: 0 });
	}
	moodFormatter(cell, row){
		let autoClassification = row.autoClassification
		if(row && autoClassification){
			const determineMood = () => {
				let errandMood = "", errandEmoji = "";

				if (autoClassification) {
					const moodClassify = {};
					const validJSONClassify = JSON.parse(autoClassification);
					for (var prop in validJSONClassify) {
						if (Object.prototype.hasOwnProperty.call(validJSONClassify, prop)) {
							if (prop === "sentiment") {
								moodClassify[prop] = validJSONClassify[prop];
							}
						}
					}
					if (moodClassify["sentiment"] === "positive") {
						errandMood = "Positive";
						errandEmoji = "happy";
					} else if (moodClassify["sentiment"] === "negative") {
						errandMood = "Negative";
						errandEmoji = "sad";
					} else {
						errandMood = "Neutral";
						errandEmoji = "neutral";
					}
				}

				return { errandMood, errandEmoji };
			};

			const renderEmoji = (emotion) => {
				switch (emotion) {
					case 'happy':
						return '😃';
					case 'sad':
						return '😞';
					case 'neutral':
						return '😐';
					default:
						return '😐';
				}
			};

			const { errandMood, errandEmoji } = determineMood();

			return (
				<div>
					<span title={errandMood}>{renderEmoji(errandEmoji)}</span>
				</div>
			);
		}
	}
	priorityFormatter(cell, row){
		let hideDueDate = true, hp, hideHp = true, collabStatus = "", collabIcon = "icon-member", hideCollabIcon = true, status = "", participants = "", statusMsg, hideStatusMsg = true;
		let hideExpireChat = true, hideEndedChat = true, chatExpiredIcon, chatEndedIcon, dueDateIcon;
		let expired = false, ended = false, dueDate = "";
		let postponeDate="", postponeDateIcon, hidePostponeDate = true,
			slaTimeClass, slaTimeTextClass, slaTimeMsg, slaTimeDetail, slaTimeInfo, hideSlaTime = true,
			theme;
		let postponeWarningClass = 'warning';

		if(this.props.dueDateFeature && !row.chat &&
			row.dueDate && row.dueDate.match(parseDate)) {
				dueDate = row.dueDate.replace(" 00:00", "");
				dueDateIcon = createMarkup("<i class='icon-clock'></i>");
				hideDueDate = false;
		}
		if(typeof row.wasPostponed !== 'undefined' && row.wasPostponed){
			postponeWarningClass = "warningOver"
			postponeDateIcon = createMarkup("<i class='icon-calendar-plus postpone-calendar-icon-list'></i>");
			hidePostponeDate = false;
			postponeDate = I("Postponed time is up for the errand")
		}
		if(this.props.postponeDateFeature && !row.chat && row.postponeDate > 0){
			postponeWarningClass = 'warning';
			postponeDate= moment.unix(row.postponeDate).format('YYYY/MM/DD h:mm a')
			postponeDateIcon = createMarkup("<i class='icon-calendar-plus postpone-calendar-icon-list'></i>");
			hidePostponeDate = false;
		}
		if(row.highpriority){
			hp = createMarkup("<i class='icon-caution'></i>");
			hideHp = false
		}
		if(row.collaboration) {
			const c = row.collaboration;
			if(this.props.collaborationInfo){
				participants = this.props.collaborationInfo;
			}
			if(row.id === this.state.collabShowStatus){
				collabStatus = <div className='notice-message'>
							<div className='notice-message-title'>
							{c.status}</div>
							<div className='notice-message-text'>{participants}</div>
						</div>
			}
			if(c.light) {
				collabIcon = "icon-user-fill light-bulb-on";
			}
			hideCollabIcon = false;
		}
		if(row.chat){
			if(row.chat.expired){
				hideExpireChat = false;
				chatExpiredIcon = createMarkup("<i class='icon-clock'></i>");
			}
			if(row.chat.dead){
				hideEndedChat = false;
				chatEndedIcon = createMarkup("<i class='icon-caution'></i>");
			}
		}
		if( typeof row.style !== "undefined" ){
			theme = row.style.themeWarning;
		}
		if (!row.answered && theme != "System Warning Theme" && !row.chat && row.generalAlertTime > 0)
		{
			slaTimeClass = "icon-bell";
			slaTimeTextClass = "block-with-text two-lines";
			slaTimeMsg = "SLA time remaining";
			if(this.props.slaTime){
				slaTimeDetail = this.props.slaTime.days + " days " + this.props.slaTime.hours + " hours " + this.props.slaTime.mins + " minutes";
			}
			if(row.id === this.state.slaShowTime){
				slaTimeInfo = <div className='notice-message'>
								<div className="notice-message-title">{slaTimeMsg}</div>
								<div className={"notice-message-text "+slaTimeTextClass}>{slaTimeDetail}</div>
							 </div>
			}
			hideSlaTime = false;
		}
		return <div className="wf-notices">
					<ul className="wf-list-view-status-icons">
					<div style={{fontStyle: 'normal'}}>
					</div>
						<li className="message" hidden={hideStatusMsg}>{statusMsg}</li>
						<li className="warning" title={dueDate} hidden={hideDueDate} dangerouslySetInnerHTML={dueDateIcon} />
						<li className={postponeWarningClass} title={postponeDate} hidden={hidePostponeDate} dangerouslySetInnerHTML={postponeDateIcon} />
						<li className="warning" title={I("Chat expired")} hidden={hideExpireChat} dangerouslySetInnerHTML={chatExpiredIcon} />
						<li className="high-priority" hidden={hideHp} dangerouslySetInnerHTML={hp} />
						<li className="high-priority" title={I("Chat ended")} hidden={hideEndedChat} dangerouslySetInnerHTML={chatEndedIcon} />
						<li className="waiting-users" title={row.collaboration.status ? row.collaboration.status : ""} hidden={hideCollabIcon} onClick={(e) => this.handleCollabClick(e, row.id, e)} data-qa-id={"QA_errandFlag_collab_listView"}>
							<i className={collabIcon} onMouseOver={(e) => this.handleGetCollabInfo(row.id)}
								onMouseLeave={this.handleResetCollabInfo}></i>
							{collabStatus}
						</li>
						{this.props.showSlaTimeFeature &&
						<li className="warning" data-qa-id={"QA_errandFlag_slaTime_listView"} hidden={hideSlaTime}>
							<i className={slaTimeClass} onMouseOver={(e) => this.handleGetSlaTime(row.id)}
								onMouseLeave={this.handleResetSlaTime}></i>
							{slaTimeInfo}
						</li>
						}
						
					</ul>
					
				</div>
			
	}
	loadPreview(id){
		if(this.props.onLoadPreview && id && this.props.previewActivated.activate){
			this.props.onLoadPreview(id);
		}
	}
	flagFormatter(flag, row){
		let flags = [];
		if(flag && flag.length > 0) {
			flag.map(function(v) {
				flags.push(<ColoredFlag key={row.id+"-"+v} className="icon-flag" color={v}></ColoredFlag>);
			});
		}
		return <div>{flags}</div>
	}
	subjectFormatter(cell, row){
		const { previewActivated, previewData, onActiveSubject, activeSubject } = this.props;
		const { activate, delaytime, instant } = previewActivated;
		const { id, channel, date, from, sourceName } = row;
		let subject = cell;
		let serviceSource = channel;
		if(channel == "Chat" && sourceName){
			serviceSource = sourceName;
		}
		let cFrom = cleanChannelIdentifier(serviceSource, from);
		let dataQAId = "preview" + id, subjectClass
			, targetElement = 'PreviewTooltip-' + id;
		if (activate && activeSubject.show && activeSubject.id === id) {
			subjectClass = 'active';
		};
		if(row.chat) {
			let chat = row.chat;
			if(chat.messages && chat.messages.length > 0) {
				let message = chat.messages;
				if(message[0].fromClient){
					subject = stripHTML(message[0].text);
				}else{
					subject = "";
				}
			}
		}
		return (
			<span>
				<div id={targetElement} data-qa-id={dataQAId}>
					<div className={subjectClass} dangerouslySetInnerHTML={createMarkup(subject)} />
				</div>
				{(!this.props.mobile && this.props.canShowPreview && activate) &&  <ErrandPreviewItem
					activate={activate}
					body={previewData}
					channel={channel}
					date={date}
					delayTime={delaytime}
					hideMeta={true}
					from={cFrom}
					id={id}
					instantPreview={instant}
					onActiveSubject={onActiveSubject}
					placement={"bottom-start"}
					subject={subject}
					targetElement={targetElement}
					initiator={PREF_LIST_VIEW}
					onLoad={this.loadPreview} />}
			</span>
		);
	}
	sizePerPageListChange(sizePerPage) {
	}
	onPageChange(page, sizePerPage) {
		let toPage = page-1;
		if(this.props.onPageChange) {
			this.props.onPageChange(toPage, sizePerPage);
		}
	}
	renderPaginationPanel(props){
		let currentPage = 0;
		if(this.props.totalList > 0){
			currentPage = this.props.currentOffset+1;
		}
		return (
			<div className="wf-pagination">
				<div className="wf-pagination-page">{ props.components.pageList }</div>
				<div className="wf-pagination-current">
					{
						this.props.listReady &&
							I('Showing {CURRENT_PAGE} of {TOTAL_PAGES}')
								.replace('{CURRENT_PAGE}', currentPage)
								.replace('{TOTAL_PAGES}', props.totalPages)
					}
				</div>
				<div className="wf-pagination-size">
					<PaginationSize size={ERRAND_PAGINATION_SIZE_OPT} sizePerPage={props.sizePerPage} onChangeSizePerPage={props.changeSizePerPage} />
				</div>
			</div>
		);
	}
	customSelector(props){
		const { type, checked, disabled, onChange, rowIndex } = props;
		const { selectAll, onSelectAll } = this.props;
		if (rowIndex === 'Header') {
			return (
				<div className="conversation-checkbox">
					<SquareCheckbox
						className={"v5-checkbox big"}
						data-qa-id={"QA_ErrandCbInput"+rowIndex}
						checked={selectAll}
						onClick={()=> onSelectAll(!selectAll)}
						// label={itemLabel}
					/>
				</div>
			);
		}
		return (
			<div className="conversation-checkbox">
				<SquareCheckbox
					className={"v5-checkbox big"}
					checked={checked}
					onClick={this.onRowSelect}
					id={'checkbox' + rowIndex}
				>
					<input
						id={'checkbox' + rowIndex}
						type={ type }
						name={ 'checkbox' + rowIndex }
						data-qa-id={"QA_ErrandCbInput"+rowIndex}
						checked={ checked }
						disabled={ disabled }
						onChange={ e=> onChange(e, rowIndex) }
						ref={ input => {
							if (input) {
								input.indeterminate = props.indeterminate;
							}
						} }/>
					<div className='check'></div>
				</SquareCheckbox>
			</div>
		);
	}
	formatRowStyle(row, rowIndex){
		let rowClass = '';
		if( typeof row.style !== "undefined" ){
			let theme = row.style.themeWarning;
			if(row.grouped != 0) {
				rowClass = 'grouped';
			}
			if(theme == "System Warning Theme"){
				rowClass += ' errand-expired';
			}else if(theme == "System Warning Alert Theme"){
				rowClass += ' errand-warning';
			}else if (row.statusMessage === I("New")){
				rowClass += ' new-row';
			}
		}
		if(this.props.activeCreatedErrand === row.displayId || this.props.sipGetCurrentEid === row.id) {
			if(this.props.activeCreatedErrand) {
				rowClass += ' has-active-call outgoing';
				if(this.props.sipMakeCallCurrentErrand) {
					rowClass += ' opened';
				}
			} else {
				rowClass += ' has-active-call';
			}
		}
		return rowClass;
	}
	sortStyle(order,field){
		return "active";
	}
	setNoData(){
		let noErrandMsg = "";
		if(this.props.list.length == 0){
			noErrandMsg = <div>{I("Loading")} <span><i className="fa fa-spinner fa-spin"></i></span></div>;
			if(this.props.listReady){
				noErrandMsg = I("No Errands");
			}
		}
		return noErrandMsg;
	}
	renderStatusIcons(cell, row, enumObject, index) {
		let prio = this.priorityFormatter(cell, row)

		return <div className="status-icon-wrapper">
					<WfNoticeIcon
						className={"pinned-top"}
						hidden={row.pinToTop ? false : true}
						iconName={"icon-pin"}
						title={I("Pinned to top")}
					/>
					<WfNoticeIcon
						className={"locked-to-me"}
						hidden={row.lockedToMe ? false: true}
						iconName={"icon-lock"}
						title={I("Locked to me")}
					/>
					{prio}
					<WfNoticeIcon
						className={"internal-comment"}
						hidden={row.errandNotes ? false : true}
						iconName={"icon-internal-comment"}
						title={I("Internal Comment")}
					/>
				</div>
	}
	render() {
		let dataList = [], selectedData = [];
		if(this.props.list && this.props.list.length > 0) {
			$.each(this.props.list, function(i,v) {
				let info = v.data, colStatus;
				if(info.data.collaboration) {
					colStatus = info.data.collaboration;
				} else {
					colStatus = '';
				}
				let subjectSummary = info.data.subject;
				if(info.data.subject.length > 50) {
					subjectSummary = info.data.subject.substring(0,50)+'...';
				}
				dataList.push({
					wasPostponed: info.data.wasPostponed,
					chat: v.chat,
					cipherKey: info.data.cipherKey,
					status: info.data.status,
					highpriority: info.data.highpriority,
					from: info.data.fromName,
					autoClassification: info.data.autoClassification,
					channel: info.data.serviceName,
					channelId: info.data.service,
					displayId: info.data.displayId,
					id: info.data.id,
					handleId: positiveNumber(info.groupWith),
					subject: subjectSummary,
					attachment: info.data.attachments,
					errandNotes: info.data.errandNotes,
					areaName: info.data.areaName,
					organisationName: info.data.organisationName,
					addressTo: info.data.to,
					date: info.data.date,
					answered: info.data.answered,
					hasAnswer: info.data.hasAnswer,
					style: info.style,
					collaboration: colStatus,
					statusMessage: info.data.statusMessage,
					pinToTop: info.data.pinToTop,
					lockedToMe: info.data.locked,
					grouped: info.groupWith,
					dueDate: info.data.donedate,
					postponeDate: info.data.postponedate,
					sourceId: info.data.sourceId,
					sourceName: info.data.sourceName,
					//Do we have anyhing generic to detect pm from a channel? For example, VK might also have PM
					isPm: (info.data.fbPms || info.data.twitterPm),
					generalAlertTime: info.data.generalAlertTime,
					flag: info.data.flagColors
				});
				if(v.selected) {
					selectedData.push(info.id);
				}
			}.bind(this));
		}
		//FIXME: Feature group errand with thread can makes the page number
		//more than it should whereby a set of list can display
		// more than the size of list specified
		const options = {
			firstPageTitle: I('first page'),
			lastPageTitle: I('last page'),
			nextPageTitle: I('next page'),
			prePageTitle: I('previous page'),
			onSortChange: this.onSortChange,
			onRowClick: this.onRowClick,
			onPageChange: this.onPageChange,
			onSizePerPageList: this.sizePerPageListChange,
			sizePerPage: this.props.pageSize,
			pageStartIndex: 1,
			paginationSize: 3,
			withFirstAndLast: true,
			paginationPanel: this.renderPaginationPanel,
			page: this.props.currentOffset+1,
			noDataText: this.setNoData()
		};
		const selectRowProp = {
			mode: 'checkbox',
			onSelect: this.onRowSelect,
			selected: selectedData,
			customComponent: this.customSelector
		};
 
		const mobile = this.props.mobile;
		let showSentimentColumnFeature = false;
		if(features["machine-learning.text-classification"] && !features["machine-learning-hide-auto-classification-list"]) {
			showSentimentColumnFeature = true;
		}
		return (
			<div ref="workflowTable" className="workflowTable">
				<BootstrapTable data={ dataList }
					selectRow={ selectRowProp }
					remote={ true }
					pagination={ true }
					fetchInfo={ { dataTotalSize: this.props.totalList } }
					trClassName={this.formatRowStyle}
					tableHeaderClass='wf-header-borderless'
					tableBodyClass='wf-body-class'
					containerClass='wf-table-container'
					tableContainerClass='wf-table-container-class'
					headerContainerClass='wf-table-header-container'
					bodyContainerClass='wf-body-container-class'
					printable
					options={ options }
					tableStyle={{
									// display: 'grid', TO FIX
									width: '100%'
									// 'overflowX': 'auto',
									// 'overflowY': 'auto'

								}}
					version='4'
					containerStyle={{ width: "100%"}}
					>
					{/* Pass the columnWidths using index. We skip index[0] as it is auto generated
					 by the bootstrap table for the checkboxes column */}
					<TableHeaderColumn
						columnClassName='td-wf-priority-icons'
						width={mobile ? '90px' : `${this.state.columnWidths[1].width}px`}
						className="resizable-highlight"
						dataField='priority'
						dataFormat={this.renderStatusIcons}
						dataSort
						sortHeaderColumnClassName={ this.sortStyle }>{I("priority")}
					</TableHeaderColumn>
					{
						showSentimentColumnFeature &&
							<TableHeaderColumn
							columnClassName='td-wf-priority-icons'
							width={"20px"}
							className="resizable-highlight"
							dataField='mood'
							dataFormat={this.moodFormatter}
							dataSort
							sortHeaderColumnClassName={ this.sortStyle }>{""}
						</TableHeaderColumn>
					}
					{/* contact */}
					<TableHeaderColumn
						columnClassName='td-wf-from td-truncate'
						width={mobile ? '120px' : `${this.state.columnWidths[2].width}px`}
						className="resizable-highlight"
						dataField='from'
						dataFormat={this.fromFormatter}
						dataSort sortHeaderColumnClassName={ this.sortStyle }>{I("contact")}
					</TableHeaderColumn>
					{/* channels */}
					<TableHeaderColumn
						columnClassName='td-wf-channel-icons tk'
						width={isMobile ? '40px' : `${this.state.columnWidths[3].width}px`}
						className="resizable-highlight"
						dataSort
						dataAlign='center' dataField='channel'
						dataFormat={this.channelFormatter}>{I("channels")}
					</TableHeaderColumn>
					{/* Errands */}
					<TableHeaderColumn
						// width='9%'
						width={mobile ? '90px' : `${this.state.columnWidths[4].width}px`}
						className="resizable-highlight"
						dataField='id'
						isKey
						dataFormat={this.idFormatter}
						dataSort sortHeaderColumnClassName={ this.sortStyle }>{I("errand")}
					</TableHeaderColumn>
					{/* flag */}
					<TableHeaderColumn
						width={`${this.state.columnWidths[11].width}px`}
						columnClassName="td-truncate"
						className="resizable-highlight"
						dataField='flag'
						dataFormat={this.flagFormatter}>
					</TableHeaderColumn>
					{/* subject */}
					<TableHeaderColumn
						columnClassName="td-truncate"
						width={mobile ? '240px' : `${this.state.columnWidths[5].width}px`}
						className="resizable-highlight"
						dataField='subject'
						dataFormat={this.subjectFormatter}
						dataSort sortHeaderColumnClassName={ this.sortStyle }>{I("subject")}
					</TableHeaderColumn>
					{/* sort */}
					<TableHeaderColumn
						// width='3%'
						className="resizable-highlight"
						columnClassName="attachment"
						width={mobile ? '30px' : `${this.state.columnWidths[6].width}px`}
						dataField='attachment'
						dataSort
						sortHeaderColumnClassName={ this.sortStyle }>
						<i className="icon-attachment"></i>
					</TableHeaderColumn>
					{/* organization */}
					{this.props.showOrganizationColumnFeature && <TableHeaderColumn
						width={mobile ? '120px': `${this.state.columnWidths[7].width}px`}
						className="resizable-highlight"
						dataField='organisationName'
						dataSort
						sortHeaderColumnClassName={ this.sortStyle }>{I("organization")}
					</TableHeaderColumn>}
					{/* area */}
					<TableHeaderColumn
						// width='10%'
						width={mobile ? '120px' : `${this.state.columnWidths[8].width}px`}
						className="resizable-highlight"
						dataField='areaName'
						dataSort
						dataFormat={this.areaFormatter}
						columnClassName={'text-line-blue'}
						sortHeaderColumnClassName={ this.sortStyle }>{I("area")}
					</TableHeaderColumn>
					{/* address to */}
					<TableHeaderColumn
						columnClassName="td-truncate"
						// width='12%'
						width={mobile ? '140px' : `${this.state.columnWidths[9].width}px`}
						className="resizable-highlight"
						dataField='addressTo'
						dataFormat={this.toFormatter}
						dataSort
						sortHeaderColumnClassName={ this.sortStyle }>{I("address to")}
					</TableHeaderColumn>
					{/* date */}
					<TableHeaderColumn
						columnClassName="td-truncate"
						// width='10%'
						width={mobile ? '140px' : `${this.state.columnWidths[10].width}px`}
						className="resizable-highlight"
						dataField='date'
						dataFormat={this.dateFormatter}
						dataSort
						sortHeaderColumnClassName={ this.sortStyle }>{I("date")
					}</TableHeaderColumn>
					{/* <TableHeaderColumn
						columnClassName="td-truncate"
						// width='10%'
						width={mobile ? '50px' : `${this.state.columnWidths[10].width}px`}
						className="resizable-highlight"
						dataField='mood'
						dataFormat={this.dateFormatter}
						dataSort
						sortHeaderColumnClassName={ this.sortStyle }>{I("Mood")
					}</TableHeaderColumn> */}
				</BootstrapTable>
			</div>
			);
	}
}

export default WorkflowTable;
