import React, { useState } from 'react';
import { withRouter } from 'react-router';
import {
	CTX_REVIEW,
	MM_WORKFLOW,
	MM_SEARCH,
	MY_ERRANDS,
	NEW_ERRANDS,
	SEARCH_ERRANDS,
	SEARCH_NEW_ERRANDS,
	SEARCH_MY_ERRANDS,
	SEARCH_CLOSE_ERRANDS,
	MM_SEARCHANSWERS,
	MM_EXPORT,
	MM_MESSAGE,
	PATH_BREADCRUMBS_TEXT,
	PATH_SEARCH_RESULT,
	PATH_SEARCH,
	PATH_ERRANDS,
	PATH_EXPORT,
	PATH_MESSAGES,
	PATH_SEARCH_ANSWER,
	PATH_ALL_ERRANDS,
	PATH_CLOSED_ERRANDS,
	PATH_AREAS,
	PATH_COMMENTS,
	PATH_KNOWLEDGE_BASE,
	PATH_CUST_CONTACTS,
	PATH_CUST_NOTES,
	PATH_EE_CONTACTS,
	PATH_TAGS,
	REVIEW_ERRANDS,
	VIP_ERRANDS
} from '../../common/v5/constants';
import Breadcrumb from '../../reactcomponents/Breadcrumb'
import { BreadcrumbItem } from 'reactstrap'
import { ErrandFoldersWrap } from '../../views/v5/workflow'
import MenuCtnr from '../../common/v5/menuCtnr'
const FolderFilterDD = ({text, counter, children}) => {

	const [show, setShow] = useState(false)
	const mouseEnter = () => setShow(true)
	const mouseLeave = () => setShow(false)

	return (
		<span onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className='folder-breadcrumb'>
			<span className='label'>{text} {counter}</span>
			<div className="breadcrumb-dropdown" hidden={!show}>
				<ul>
					{children}
				</ul>
			</div>
		</span>
	)
}

class WorkflowBreadcrumbsBase extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.getFolderName = this.getFolderName.bind(this);
	}
	handleClick(path) {
		if(path === MY_ERRANDS) {
			this.props.onClickPath(path, this.props.myErrandFolder);
		}
		else if(path === PATH_SEARCH_RESULT) {
			this.props.history.push(`${process.env.PATH_PREFIX}v5/search`);
		}
	}
	getFolderName(id) {
		const folders = this.props.userFolders;
		let folderName = "";
		for(let i=0;i<folders.length;i++){
			let folder = folders[i];
			if(folder.id == id){
				folderName = folder.value;
				break;
			}
		}
		return folderName;
	}
	isErrandContext = () => {
		switch (this.props.currentContext) {
			case MY_ERRANDS:
			case NEW_ERRANDS:
			case VIP_ERRANDS:
			case REVIEW_ERRANDS:
				return true
			default:
				return false
		}
	}
	render() {
		const { currentContext, ...p } = this.props;
		let path1 = "", path2 = "", path3, counter = "", searchedCounter = "", totalSearchResult = 0
		, showCustomFolder = false;
		if(p.activeMainMenu === MM_WORKFLOW){
			path1 = PATH_ERRANDS;
			path2 = currentContext;
			path3 = "";
			let totalErrand = 0;
			if(path2 === MY_ERRANDS) {
				totalErrand = p.errandCount.my.total + p.chatErrandCount;
				if((p.filter.selectedFolder !== 0) && (p.filter.selectedFolder !== p.myErrandFolder)) {
					showCustomFolder = true;
					path3 = this.getFolderName(p.filter.selectedFolder);
				}
			}else{
				if (path2 == NEW_ERRANDS) {
					totalErrand = p.errandCount.new.total;
				} else if (path2 != CTX_REVIEW) {
					path2 =  PATH_SEARCH_RESULT;
					if(currentContext === SEARCH_ERRANDS) {
						path3 = PATH_ERRANDS;
						totalSearchResult = p.totalErrandsResults;
					}else if (currentContext === SEARCH_MY_ERRANDS) {
						path3 = MY_ERRANDS;
						totalSearchResult = p.totalMyErrandResults;
					}else if (currentContext === SEARCH_NEW_ERRANDS) {
						path3 = PATH_ALL_ERRANDS;
						totalSearchResult = p.totalAllErrandResults;
					}else if (currentContext === SEARCH_CLOSE_ERRANDS) {
						path3 = PATH_CLOSED_ERRANDS;
						totalSearchResult = p.totalCloseErrandResults;
					}else if (currentContext == VIP_ERRANDS) {
						path2 = VIP_ERRANDS;
					}
				}
			}
			if(totalErrand > 0) {
				counter = "("+totalErrand+")";
			}
		}else{
			switch (p.activeMainMenu) {
				case MM_SEARCH:
					path1 = PATH_SEARCH;
					path2 =  PATH_SEARCH_RESULT;
					if(currentContext === SEARCH_ERRANDS) {
						path3 = PATH_ERRANDS;
						if(p.globalSearchType.isErrands){
							path3 = PATH_ERRANDS;
							totalSearchResult = p.totalErrand;
						}else if(p.globalSearchType.isAreas){
							path3 = PATH_AREAS;
							totalSearchResult = p.totalAreas;
						}else if(p.globalSearchType.isErrandNotes){
							path3 = PATH_COMMENTS;
							totalSearchResult = p.totalErrandNotes;
						}else if(p.globalSearchType.isKnowledgeBase){
							path3 = PATH_KNOWLEDGE_BASE;
							totalSearchResult = p.totalKBs;
						}else if(p.globalSearchType.isContactCard){
							path3 = PATH_CUST_CONTACTS;
							totalSearchResult = p.totalContacts;
						}else if(p.globalSearchType.isCustomerNotes){
							path3 = PATH_CUST_NOTES;
							totalSearchResult = p.totalCustomerNotes;
						}else if(p.globalSearchType.isEEContacts){
							path3 = PATH_EE_CONTACTS;
							totalSearchResult = p.totalExternalExpertContacts;
						}else if(p.globalSearchType.isTags){
							path3 = PATH_TAGS;
							totalSearchResult = p.totalTags;
						}
					}
					break;
				case MM_SEARCHANSWERS:
					path1 = PATH_SEARCH_ANSWER;
					break;
				case MM_MESSAGE:
					path1 = PATH_MESSAGES;
					break;
				case MM_EXPORT:
					path1 = PATH_EXPORT;
					break;
				default:
					// statements_def
					path1 = p.activeMainMenu;
					break;
			}
		}
		searchedCounter = "("+totalSearchResult+")";
		let path3Text = PATH_BREADCRUMBS_TEXT[path3];
		if(showCustomFolder) {
			path3Text = path3;
		}
		let isErrandContext = this.isErrandContext();
		return (
			<Breadcrumb>
				{/* <BreadcrumbItem tag="span" href="#">{PATH_BREADCRUMBS_TEXT[path1]}</BreadcrumbItem> */}
				<BreadcrumbItem tag="span" href="#">
					<MenuCtnr visible={true} hoverable={true} />
				</BreadcrumbItem>
				{
					path2 &&
						<BreadcrumbItem path2={path2} tag="span" href="#" onClick={() => this.handleClick(path2)} active={path3 ? false : true}>
							{isErrandContext ?
							<FolderFilterDD
								text={PATH_BREADCRUMBS_TEXT[path2]}
								counter={counter}
								>
								<ErrandFoldersWrap
									chatErrandCount={p.chatErrandCount}
									currentContext={currentContext}
									folders={p.userFolders}
									isSearch={false}//checkback
									myErrandFolder={p.myErrandFolder}
									onFolderClick={p.onFolderClick}
									onReviewClick={p.onReviewClick}//
									onSetGridFolderIndex={p.onSetGridFolderIndex}//
									reviewEnabled={p.reviewEnabled}//
									selectedFolder={p.filter.selectedFolder}//
									vipCount={p.vipCount}//
									vipTags={p.vipTags}
									wfSettings={p.wfSettings}//
									gridFolderIndex={p.gridFolderIndex}//
									gridView={false}
									realTimeCounter={true}
									errandCount={p.errandCount}
								/>
							</FolderFilterDD>
							:
							(PATH_BREADCRUMBS_TEXT[path2] + counter)
							}
						</BreadcrumbItem>
				}
				{
					path3 &&
						<BreadcrumbItem tag="a" href="#" active >{path3Text} {searchedCounter}</BreadcrumbItem>
				}
			</Breadcrumb>
		);
	}
}

const WorkflowBreadcrumbs = withRouter(WorkflowBreadcrumbsBase);

export default WorkflowBreadcrumbs;
