import { useEffect, useState } from 'react';
export const useDarkMode = () => {
    const [theme, setTheme] = useState(() => false);
    const [mountedComponent, setMountedComponent] = useState(false)
    const setMode = mode => {
        localStorage.setItem('theme', mode)
        setTheme(!theme)
    };

    const themeToggler = () => {
        theme === true ? setMode('light') : setMode('dark');
    };

    useEffect(() => {
        const localTheme = localStorage.getItem('theme');
        localTheme && localTheme == 'dark' ? setTheme(true) : setTheme(false);
        setMountedComponent(true)
    }, []);
    return [theme, themeToggler, mountedComponent]

};
